@use "../node_modules/bootstrap/scss/bootstrap";

body {
  margin: 0;
  padding-bottom: 50px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}


header {
  background: #FFFFFF;
  border: 1px solid #E6E6E6;
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 10px 0;
  height: 46px;
}

header>div {
  width: 525px;
}

/* spinner/processing state, errors */
.spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}

.spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.spinner:before,
.spinner:after {
  position: absolute;
  content: '';
}

.spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #5469d4;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}

.spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #5469d4;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}

#payment-form {
  width: 100%;
  align-self: center;
  box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
    0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
  border-radius: 7px;
  padding: 40px;
}


.payment_button {
  background: #5469d4;
  font-family: Arial, sans-serif;
  color: #ffffff;
  border-radius: 4px;
  border: 0;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
}

.payment_button:hover {
  filter: contrast(115%);
}

.payment_button:disabled {
  opacity: 0.5;
  cursor: default;
}

#payment-message {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  padding-top: 12px;
  text-align: center;
}

#payment-element {
  margin-bottom: 24px;
}

.membership .card {
  box-sizing: border-box;
  background: #FFFFFF;
  border: none;
  // box-shadow: 0px 11px 9px rgb(158 158 158 / 8%);
  // border-radius: 10px;
  margin: 0 auto;
  position: relative;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;

  a {
    text-decoration: none;
  }
}

.mw-container {
  max-width: 525px !important;
}

.membership .card-footer {
  border-color: #EEEEEE;
  background: none;
}

.membership .form-check {
  float: right;
}

.form-check .form-check-input {
  border: 1px solid #777777;
  width: 20px;
  height: 20px;
}

.card-text {
  font-weight: 400;
  font-size: 12px;
  color: #777777;
}

.card-footer dt {
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  align-items: center;
  letter-spacing: 0.01em;
  color: #646464;
}

.card-footer dl {
  font-size: 12px;
  line-height: 14px;
  align-items: center;
  letter-spacing: 0.01em;
  color: #000000;
  margin-bottom: 0;
}

.price{
  color: #1d1d1d;
}

h5.card-title {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #000000;
  margin-bottom: 0;
}


h6.card-title {
  font-weight: 600;
  font-size: 12px;
  line-height: 19px;
  color: #000000;
  margin-bottom: 0;
}

.hr-text {
  line-height: 1em;
  position: relative;
  outline: 0;
  border: 0;
  color: #000000;
  text-align: center;
  height: 1.5em;
  opacity: 1;
  margin: 2rem 0;

  &:before {
    content: '';
    background: #D9D9D9;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 1px;
  }

  &:after {
    content: attr(data-content);
    position: relative;
    display: inline-block;
    color: #000;
    padding: 0 1.5em;
    line-height: 1.5em;
    background-color: #fff;
    font-weight: 600;
  }
}

.form-floating .form-control {
  border: 1px solid #E5E5E5;
  border-radius: 5px;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.02);
}

.btn-icon {
  vertical-align: sub;
}

.upload-info {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d;
}

.link-account {
  font-size: 12px;
  font-weight: 600;
  text-decoration: none;
}

form .form-label {
  font-size: 12px;
  margin-bottom: 3px !important;
}

.accordion-button {
  font-size: 14px !important
}

.txnumber {
  font-size: 10px;
}


.topPackages {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-bottom: 1px solid #eee;
}

.card.packages-item{
  border-radius: 0;
}
.packages-item .card-footer:last-child{
  border-radius: 0 !important;
}

.packages-item .card-footer,
.selectDate .card-footer {
  border-bottom: 1px solid #eee;
}

.selectDate .startDate {
  font-size: 12px;
  margin-bottom: 3px !important;
}

.selectDate .selectHeader{
  font-size: 12px;
}
.selectDate input.form-control{
  font-size: 12px;
}

.card.selectDate-item{
  border-radius: 0;

  .card-footer:last-child{
    border-radius: 0;
  }
}


.card.selectDate-item:not(:first-child){
  margin-top: 20px;
}

 

input {
  position: relative;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background-position: right;
  padding: 8px;
  background-size: auto;
  cursor: pointer;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  width: auto;
}

.alert{
  font-size:12px;
  padding: 7px !important;

  p:last-child{
    margin-bottom: 0;
  }
}

.uploadIcon{
  border-radius: 50%;
  padding: 8px;
  background: #E4E6EB;
  width: 35px;
  height: 35px;
  display: flex;
  margin-bottom: 10px;
}
.uploadHeader{
  color: #282828;
  font-weight: 600;
  margin-bottom: 0;
}
.uploadSubheader{
  color: #5F6266;
  margin-bottom: 0;
  font-size: 10px;
}

.extraText{
  font-size: 12px;
}

.dropzone:hover {
  background: #E9EBED !important;
  cursor: pointer;
}


.close{
  right: 5px;
  position: absolute;
  background-color: #fff;
  top: 5px;
  border-radius: 50%;
}


.close:hover{
  cursor: pointer;
  background-color: #F2F2F2;
}

.mini-font{
  font-size: 12px;
}
.none-border-left{
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.cursor-pointer{
  cursor: pointer;
}